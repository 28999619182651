import React, { useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "@material-ui/core/Button";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import Chip from "@material-ui/core/Chip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import clsx from "clsx";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import { useFullscreenMode } from "../../../hooks/useFullscreenMode.js";
import "react-pdf/dist/Page/AnnotationLayer.css";
import DownloadLink from "../../../SharedComponents/DownloadLink.jsx";
import { extractFileNameFromUrl } from "../../../Utils/misc.js";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const useStyles = makeStyles((theme) => ({
  theoryRoot: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    margin: "0 0 30px",
  },
  infoText: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    [theme.breakpoints.up("lg")]: {
      flexGrow: "unset",
      height: 600,
    },
  },
  iFrame: {
    width: "100%",
    border: "none",
    flexGrow: 1,
    minHeight: 622,
  },
  pdfToolbar: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    alignItems: "center",
  },
  pdfToolbarMargin: {
    marginTop: 10,
  },
  fullscreenButton: {
    minWidth: 36,
    backgroundColor: "rgba(230, 230, 230, 0.4)",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "rgba(230, 230, 230, 0.9)",
    },
  },
  fullscreenDoc: {
    overflowX: "auto",
  },
  downloadLink: {
    padding: 10,
    backgroundColor: "#f5f5f5",
    borderRadius: 5,
  },
}));

/**
 * @returns {*}
 * @constructor
 */
const Theory = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { currentDeck } = useCourseProvider();
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  /** @type {React.MutableRefObject<HTMLElement>} */
  const pdfWrapperRef = useRef();
  const { isFullscreen } = useFullscreenMode();
  const [pdfWrapperMinHeight, setPdfWrapperMinHeight] = useState(50);

  /** */
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  /**
   * @param {Number} increment
   * @returns {function(...[*]=)}
   */
  const changePage = (increment) => () => {
    const newPageNumber = currentPage + increment;
    if (newPageNumber < 1 || newPageNumber > numPages) {
      return;
    }
    setCurrentPage(newPageNumber);
  };

  /** */
  const onPdfPageSuccessfullyRendered = () => {
    if (!isFullscreen) {
      setPdfWrapperMinHeight(pdfWrapperRef.current.getBoundingClientRect().height);
    }
  };

  if (!currentDeck) {
    return <Box className={classes.infoText}>{t("loading")}</Box>;
  }

  const additionalFileUrl = currentDeck.theory.additionalFileUrl;

  if (!currentDeck.theory.html && !currentDeck.theory.pdf && !additionalFileUrl) {
    return <Box className={classes.infoText}>{t("noTheory")}</Box>;
  }

  const additionalFileName = additionalFileUrl ? extractFileNameFromUrl(additionalFileUrl) : null;

  return (
    <Box className={classes.theoryRoot}>
      {additionalFileUrl && (
        <Box margin="20px">
          <DownloadLink
            className={classes.downloadLink}
            linkText={t("theoryAdditionalFile", { filename: additionalFileName })}
            fileName={additionalFileName}
            href={additionalFileUrl}
          />
        </Box>
      )}
      {currentDeck.theory.html && <iframe className={classes.iFrame} src={currentDeck.theory.html.base64Src()} />}
      {currentDeck.theory.pdf && (
        <FlexboxHorizontal
          ref={pdfWrapperRef}
          data-tag="pdf wrapper"
          width="855px"
          margin="20px 0 50px"
          justifyContent="space-between"
          position="relative"
          minHeight={pdfWrapperMinHeight}
        >
          {numPages && (
            <Button color="primary" disabled={currentPage === 1} onClick={changePage(-1)}>
              <ArrowBackIosRoundedIcon />
            </Button>
          )}
          <Document
            className={clsx({ [classes.fullscreenDoc]: isFullscreen })}
            file={currentDeck.theory.pdf.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isFullscreen ? undefined : 600}
              height={isFullscreen ? pdfWrapperRef.current.getBoundingClientRect().height : undefined}
              renderAnnotationLayer={true}
              renderTextLayer={false}
              onRenderSuccess={onPdfPageSuccessfullyRendered}
            />
          </Document>
          {numPages && (
            <>
              <Button color="primary" disabled={currentPage === numPages} onClick={changePage(1)}>
                <ArrowForwardIosRoundedIcon />
              </Button>
              <FlexboxHorizontal className={clsx(classes.pdfToolbar, { [classes.pdfToolbarMargin]: isFullscreen })}>
                <Chip label={`${currentPage} / ${numPages}`} />
                {!isFullscreen && (
                  <Button
                    className={classes.fullscreenButton}
                    onClick={() => pdfWrapperRef.current.requestFullscreen().then()}
                  >
                    <FullscreenIcon />
                  </Button>
                )}
                {isFullscreen && (
                  <Button className={classes.fullscreenButton} onClick={() => document.exitFullscreen()}>
                    <FullscreenExitIcon />
                  </Button>
                )}
              </FlexboxHorizontal>
            </>
          )}
        </FlexboxHorizontal>
      )}
    </Box>
  );
};

export default Theory;
