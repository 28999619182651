export default {
  title: {
    login: "%{school} - Connexion",
    registration: "%{school} - Inscription",
    subjects: "%{school} - Choix de la matière",
    subject: "%{school} - Matière",
    offer: "%{school} - Offre",
    home: "%{school} - Tableau de bord",
    dictionary: "%{school} - Dictionaire",
    events: "%{school} - Événements",
    news: "%{school} - Actualité",
    profile: "%{school} - Profil",
    learn: "%{school} - Apprentissage",
    pay: "%{school} - Paiement pour la matière",
    checkInSystem: "Êtes-vous déjà inscrit?",
    setPassword: "Saisissez le mot de passe",
    createAccount: "Créez un compte dans le système.",
  },
  email: "Email",
  personalLink: "Lien personnel",
  login: "Identifiant",
  name: "Nom",
  phone: "Téléphone",
  aboutMe: "À propos de moi",
  registration: "Inscription",
  password: "Mot de passe",
  currentPassword: "Mot de passe actuel",
  newPassword: "Nouveau mot de passe",
  confirmPassword: "Confirmez le mot de passe",
  confirmNewPassword: "Confirmez le nouveau mot de passe",
  forgotPassword: "Mot de passe oublié?",
  passwordsMatch: "Les mots de passe ne correspondent pas",
  resetPassword: "Mot de passe a bien été reinitialisé, vérifiez votre email pour plus de détails.",
  resetConfirm: "Voulez-vous réinitialiser votre mot de passe?",
  enter: "Se connecter",
  studentEnter: "Connexion étudiant",
  check: "Suivant",
  emailIsNotRegistered: "L'utilisateur avec cette adresse e-mail n'existe pas",
  logout: "SE DÉCONNECTER",
  cancel: "Annuler",
  yes: "Oui",
  no: "Non",
  course: "Cours",
  dictionary: "Dictionnaire",
  events: "Événements",
  news: "Actualités",
  words: "Mots",
  wordsAreHere: "Ces mots se trouvent ici:",
  allWords: "Tous les mots",
  phrases: "Phrases",
  videos: "Vidéos",
  exercises: "Exercices",
  theory: "Théorie",
  theoryAlt: "Résumé",
  theoryAdditionalFile: "Télécharger le fichier supplémentaire (%{filename})",
  topic: "Sujet",
  lesson: "Leçon",
  profile: "Profil",
  orders: "Commandes",
  payment: "Paiements",
  leaderboard: "Classement",
  time: "Temps",
  points: "Points",
  courses: "Mes cours",
  currentLang: {
    en: "English",
    ru: "Русский",
    fr: "Français",
    uk: "Українська",
  },
  loading: "Chargement...",
  noTheory: "Aucune théorie disponible pour cette leçon",
  startTraining: "Commencer l'entraînement",
  missions: "Missions",
  branches: "Sections",
  easy: "Facile",
  expert: "Expert",
  wordToLearn: "Réviser plus tard",
  iKnowWord: "Je le sais",
  videoVocabulary: "Lexique vidéo",
  passed: "complété",
  unsupportedExercise: "Ce type d'exercice (%{type}) ne peut pas encore être pris en charge.",
  changeCourse: "Changer la matière",
  currentCourse: "Cours actuel:",
  allSubjects: "Tous les cours",
  subject: "Matière",
  group: "Groupe",
  start: "Commencer",
  chooseSubject: "Choisissez une matière à étudier",
  hourValue: "%{value} h",
  completed: "Terminé",
  notCompleted: "Non terminé",
  paidBranch: "Cette section est payante. Veuillez l’acheter pour l’étudier.",
  buyBranch: "Acheter cette section pour %{price} %{currency}",
  paidSubject: "Cette matière est payante. Veuillez l’acheter pour l’étudier.",
  buySubject2: "Acheter cette matière pour  %{price} %{currency}",
  decksItemNotOpen: "Pour accéder a cette leçon, vous devez terminer la leçon précédente",
  decksItemNotPurchasedBranch: "Le sujet de cette leçon est bloqué",
  decksItemNotOpenLevel: "Passez le niveau précédent pour ouvrir cette leçon",
  decksItemNotOpenBranch: "Pour accéder à cette section, vous devez terminer la section précédente",
  deckAccessDenied: "Cette leçon n'est pas encore disponible pour vous",
  openOnDate: "S'ouvrira le %{date}",
  levelAccessIsDenied: "Ce niveau n’est pas accessible pour vous",
  youAreDeactivated: "Votre compte a été désactivé, veuillez contacter votre école",
  youAreDeactivatedShort: "Votre compte est désactivé",
  youAreHaveNotPaidOffers: "Vous avez des offres non payées",
  yourCertificates: "Vos certificats",
  emptyDeck: "Leçon est vide",
  nextDeck: "Leçon suivante",
  next: "Suivant",
  proceed: "Continuer",
  skip: "Passer",
  more: "Plus",
  exit: "Quitter",
  details: "Voir les détails",
  profileEdit: "Modifier le profil utilisateur",
  userInfo: "Informations utilisateur",
  of: "de",
  back: "Retour",
  levelPackExist: "Vous avez déjà cet ensemble de niveaux",
  awaitingPayment: "En attente de paiement",
  bought: "Acheté",
  boughtUntilDate: "Acheté jusqu’au %{endDate}",
  availableUntilDate: "Disponible jusqu’au %{endDate}",
  showMore: "Afficher plus",
  hide: "Cacher",
  communication: "Contact avec l’école",
  askQuestion: "Poser une question",
  noDescription: "Description manquante",
  onePerMonth: "1 par mois",
  semiannually: "Deux fois par an",
  onceAYear: "Une fois par an",
  stopSubscribe: "Arrêter l’abonnement",
  enable: "Activer l’abonnement",
  expired: "Expiré",
  buySubject: "Acheter la matière",
  buyOffer: "Acheter l’offre",
  subscribeOffer: "S’abonner",
  firstPayment: "Premier paiement",
  accessDaysWarning: "%{smart_count} jour restant |||| %{smart_count} jours restants",
  accessDemoDaysWarning: "Démo: %{smart_count} jour restant |||| Démo: %{smart_count} jours restants",
  closeButton: "Fermer",
  Exercises: {
    tabTitle: "Exercises",
    skip: "Passer",
    check: "Vérifier",
    typeAnswer: "Écrivez votre réponse",
    next: "Suivant",
    rightAnswerWas: "TLa bonne réponse était:",
    congratulation: "Félicitations!",
    congratText: "Vous avez terminé tous les exercices de cette leçon!",
    wrongAnswer: "Votre réponse est incorrecte",
    answerSaved: "Réponse sauvegardée",
    commentToAnswer: "Commentaire sur la réponse sélectionnée:",
    appendix: {
      pdf: {
        tooltipButton: "Matériel supplémentaire",
      },
    },
    type12: {
      title: "En savoir plus",
    },
    type11: {
      title: {
        select: "Choisissez la bonne réponse",
        keyboard: "Écrivez la bonne réponse",
      },
    },
    type9: {
      title: {
        select: "Remplissez les vides en choisissant des mots",
        keyboard: "Remplissez les vides",
      },
    },
    type8: {
      title: "Trouvez les correspondances",
    },
    type7: {
      title: {
        text: "Écrivez votre réponse",
        audio: "Donnez la réponse audio",
      },
      recordingIsNotSupported: "L'enregistrement audio n'est pas pris en charge par votre navigateur.",
      audioRecordingError: "Erreur d'enregistrement audio",
      inputLabel: "Message",
      addPhoto: "Ajouter une photo/vidéo (max 5)",
      cameraInitialization: "Initialisation...",
      change: "Modifier",
      codeToApprove: "Mot de passe",
      homework: {
        teacherData: {
          audio: "Commentaire audio de l'enseignant",
          audioComment: "Commentaire audio de l'enseignant",
        },
      },
      addFiles: "Ajouter des fichiers",
    },
    type6: {
      title: "Dessinez un élément",
      checkButtonText: "Bien entendu!",
    },
    type3: {
      title: "Glissez-déposez les éléments dans l’ordre",
      hint: "Glissez les éléments ici depuis la zone ci-dessous",
    },
    type5: {
      title: "Sélectionnez les éléments<",
      hint: ">Sélectionnez les éléments corrects en bas de l’écran (cliquez pour sélectionner)",
    },
    type2: {
      title: "Choisissez l’élément manquant",
    },
  },
  Events: {
    headNotice: "L’heure de l’événement est affichée selon votre fuseau horaire",
    title: "Événements de la semaine",
    startTime: "Heure de début:",
    group: "Groupe",
    persons: "%{smart_count} personne |||| %{smart_count} personnes",
    leader: "Animateur",
    tag: "Catégorie",
    joinedCheckbox: "Avec inscription",
    joined: "Sélectionné:",
    interested: "Inscription",
    enter: "S'inscrire",
    limitPopupInfo: "ous avez atteint la limite d’inscriptions",
    eventHasStarted: "L’événement a déjà commenc!",
    registrationNotice: "Inscription réussie! Le bouton pour entrer sera actif le %{date_and_time}. Après ce moment, vous ne pourrez plus annuler votre inscription.",
    registrationNoticeNoUrl: "Inscription réussie! Après %{date_and_time} vous ne pourrez plus annuler votre inscription.",
    cancelRegistration: "Êtes-vous sûr(e) de vouloir annuler votre inscription",
    calendarLink: "Ajouter l’événement à Google Agenda",
  },
  statistic: {
    title: "Statistiques",
    points: "Points",
    phrases: "Phrases",
    missions: "Missions",
    words: "Mots",
    time: "Temps",
  },
  status: {
    wait_review: "EN ATTENTE DE VÉRIFICATION",
    on_review: "EN COURS DE VÉRIFICATION",
    approved: "CONFIRMÉ",
    not_approved: "REJETÉ",
    not_approved_allow_update: "REJETÉ (à refaire)",
  },
  homeworks: {
    tabName: "Devoirs",
    todo: "À faire",
    done: "Faits",
    topic: "Sujet",
    lesson: "Leçon",
    onlyInMobileApp: "Disponible uniquement dans une application mobile",
    todoHomework: "EN COURS DE VÉRIFICATION",
    postToDoButton: "Envoyer",
    types: {
      homework_todo: "À faire",
      exercise: "Exercice",
    },
  },
  exam: {
    popupTitle: "Leçon avec examen",
    popupDescr1: "Ceci est une leçon avec un examen. Étudiez d'abord tous les matériaux supplémentaires, puis passez aux exercices.",
    popupDescr2: `Dès que vous accédez à l'onglet "Exercices", le compte à rebours commence.`,
    popupDescr3: 'Les onglets "Théorie" et "Vidéo" ne démarrent PAS le compte à rebours de l\'examen.',
    cancelButton: "La prochaine fois",
    positiveButton: "C'est clair",
    questions: "%{smart_count} question |||| %{smart_count} questions",
    oneAttempt: "Une tentative par question",
    timeLimit: "emps limité: %{limit} min",
    noPause: "TL'examen ne peut pas être mis en pause",
    progress: "Question #%{current} sur %{total}",
    closeButton: "Fermer",
    nextButton: "Suivant",
    finishedPopupTitle: "RÉSULTAT FINAL",
    examPassed: "EXAMEN RÉUSSI",
    examNotPassed: "EXAMEN ÉCHOUÉ",
    waitHomeworks: "%{smart_count} question en cours de vérification |||| %{smart_count} questions en cours de vérification",
  },
  subjects: {
    unavailable: "La matière est actuellement indisponible",
  },
  orderStatus: {
    approved: "Approuvé",
    canceled: "Annulé",
    pending: "En attente",
  },
  notificationsTypes: {
    event: "Événement",
    invoice: "Facture",
    news: "Actualité",
    homework: "Devoirs",
    homework_todo: "Devoirs à faire",
    type: {
      exercise: "Exercice",
      homework_todo: "Devoirs à faire",
    },
  },
  notificationsAlert: {
    subscribe: "Activer les notifications",
    unsubscribe: "Désactiver les notifications",
    instructions: "Cliquez sur le cadenas 🔒 à gauche de la barre d'adresse et modifiez les paramètres des notifications comme indiqué, ou ajustez les paramètres généraux de votre navigateur.",
    permissionDefault: {
      title: "Abonnez-vous aux notifications🔔",
      body: "Recevez des alertes sur les changements de statut des devoirs, les débuts d'événements, les actualités, et plus encore. Pas de publicité, uniquement des notifications liées à votre apprentissage!",
    },
    permissionDenied: {
      title: "Les notifications sont désactivées ❌",
      body: "Vous avez désactivé les notifications. Sans elles, vous ne serez pas informé à temps des changements d'état des devoirs ou des débuts d'événements. Pour activer les notifications de nouveau, suivez les instructions ci-dessous:",
    },
    permissionGranted: {
      title: "Les notifications sont activées ✔",
      body: "Vous pouvez les désactiver si vous ne souhaitez plus être informé des mises à jour liées à votre apprentissage. Pour désactiver les notifications, suivez les instructions ci-dessous::",
    },
  },
  feedbackDialog: {
    sendFeedback: "Envoyer un retour",
    placeholder: "Parlez-nous de vos impressions",
    defaultInstruction: "Utilisez le curseur pour évaluer",
    eventInstruction: "Évaluez le déroulement de l'événement",
    submitError: "Veuillez sélectionner une note",
    submitSuccess: "Merci pour votre retour! Votre commentaire a été envoyé avec succès",
    wasNotPresent: "Je n'ai pas participé à l'événement",
    dateOfEvent: "Date de l'événement:",
  },
  congratulation: {
    mission: "Félicitations, la mission %{name} est terminée!",
  },
};
