import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import useTitle from "../../hooks/useTitle.js";
import YellowButton from "../../SharedComponents/YellowButton.jsx";
import useSubjectSelector from "../../hooks/useSubjectSelector.js";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";
import OfferCard from "./SharedComponents/OfferCard.jsx";
import useFavicon from "../../hooks/useFavicon.js";
import useExtractRouteParamInt from "../../hooks/useExtractRouteParamInt.js";
import CompanyHeader from "./SharedComponents/CompanyHeader.jsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: 40,
  },
  breadcrumbs: {
    margin: 30,

    "& .MuiBreadcrumbs-li": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  offersGrid: {
    padding: "45px 0",
  },
  smallCardBody: {
    display: "flex",
  },
  mediaSmall: {
    width: 300,
    height: 200,
    margin: "18px 0",
    flex: "0 0 auto",
    backgroundSize: "contain",
    backgroundPosition: "top center",
  },
  cardHead: {
    color: "white",
    backgroundColor: theme.palette.primary.dark,
    "& div": {
      alignSelf: "center",
    },
    "& .MuiCardHeader-content p": {
      fontWeight: 700,
    },
  },
  cardBtn: {
    margin: "8px 8px 0 0",
    "& b": {
      marginLeft: "auto",
    },
  },
}));

/** @returns {*}
 * @constructor
 */
const SubjectDetails = () => {
  const { companyName, domain } = useCompanyPublicId();
  const classes = useStyles();
  const t = useTranslate();
  const selectedSubjectId = useExtractRouteParamInt(
    `${companyName ? "/" + companyName : ""}/subjects/:subjectId`,
    "subjectId",
  );

  const { company2, user } = useAuth();
  const {
    availableSubjects,
    chooseSubject,
    company,
    userInfo,
    setPreselectedSubject,
    setPreselectedOffer,
    schoolNotFound,
  } = useBaseData();
  const history = useHistory();
  const { subjectHandler, subjectId, selectedSubject } = useSubjectSelector();
  const [selectedOffer, setSelectedOffer] = useState(/** @type {?Offer} */ null);

  const company_ = company || company2;

  const _subject = availableSubjects && availableSubjects.find((el) => el.id === selectedSubjectId);

  useTitle("title.subject", { school: (company_ && company_.name) || companyName || domain });

  useFavicon();

  useEffect(() => {
    if (_subject) {
      subjectHandler(_subject)();
    }
  }, [_subject]);

  /** */
  const enterButtonHandler = async () => {
    if (selectedOffer) {
      setPreselectedOffer(selectedOffer.id);
    }

    if (!companyName && !domain) {
      if (await chooseSubject(subjectId)) {
        history.replace("/");
      }
    } else if (companyName) {
      setPreselectedSubject(subjectId);
      history.push(`/${companyName}/auth`);
    } else {
      setPreselectedSubject(subjectId);
      history.push("/auth");
    }
  };

  if (schoolNotFound) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="lg">
      <CompanyHeader />
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link
          color="inherit"
          component="button"
          variant="body1"
          onClick={() => history.push(`${companyName ? "/" + companyName : "/subjects"}`)}
        >
          {t("allSubjects")}
        </Link>
        <Typography color="textPrimary">{selectedSubject && selectedSubject.name}</Typography>
      </Breadcrumbs>
      <Box className={classes.wrapper}>
        {selectedSubject && (
          <Box>
            <Grid container spacing={2} columns={{ md: 12 }}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    className={classes.cardHead}
                    subheader={<Typography>{`${t("subject")}: ${selectedSubject.name}`}</Typography>}
                    action={
                      <YellowButton
                        className={classes.cardBtn}
                        disabled={subjectId === 0 || selectedSubject.available === false}
                        onClick={enterButtonHandler}
                      >
                        <Typography>{t("enter")}</Typography>
                      </YellowButton>
                    }
                  />
                  <div className={classes.smallCardBody}>
                    <CardMedia
                      className={classes.mediaSmall}
                      image={(
                        selectedSubject.illustrationImageUrl ||
                        selectedSubject.illustrationImage?.url ||
                        company_.logo.url
                      )?.replaceAll("\\", "/")}
                    />
                    <CardContent>
                      {selectedSubject.available === false && (
                        <Typography color="error" gutterBottom>
                          {t("subjects.unavailable")}
                        </Typography>
                      )}
                      <Typography variant="body1">
                        {selectedSubject.description ? selectedSubject.description : t("noDescription")}
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.offersGrid}>
              {selectedSubject?.offers.map((offer) => (
                <OfferCard key={offer.id} offer={offer} user={userInfo} size={4} />
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default SubjectDetails;
