import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import PrimaryButton from "../../../SharedComponents/PrimaryButton.jsx";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";
import DiscardButton from "../../Offer/components/DiscardButton.jsx";
import BuyOrSubscribeButton from "../../Offer/components/BuyOrSubscribeButton.jsx";
import { calculateEndDateOfAccess, checkOfferRepeat } from "../../../Utils/misc.js";
import useCompanyPublicId from "../../../hooks/useCompanyPublicId.js";

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
  },
  media: {
    height: 250,
  },
  content: {
    textAlign: "center",
    padding: 30,
  },
  priceLabel: {
    position: "absolute",
    top: 7,
    left: 7,
    backgroundColor: "rgba(250,250,250,0.8)",
    borderRadius: 4,
    padding: 15,
    minWidth: 94,
    textAlign: "center",
  },
  price: {
    fontSize: 18,
    fontWeight: 800,
  },
  oldPrice: {
    fontSize: 14,
    color: "#e31b1b",
    textDecoration: "line-through",
  },
  newPrice: {
    fontSize: 18,
    fontWeight: 800,
    color: "#199f19",
  },
  cardBtn: {
    margin: 8,
  },
  levelsExistMessage: {
    color: "#1a854b",
  },
  firstPrice: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

/** */
const every = (every) => {
  switch (every) {
    case 1:
      return "onePerMonth";
    case 6:
      return "semiannually";
    case 12:
      return "onceAYear";
  }
};

/**
 * @param {Offer} offer
 * @param {UserInfo} user
 * @param {number} size
 * @returns {*}
 * */
const OfferCard = ({ offer, user, size }) => {
  const classes = useStyles();
  const t = useTranslate();
  const history = useHistory();
  const { company2 } = useAuth();
  const { company, userInfo } = useBaseData();
  const { companyName } = useCompanyPublicId();

  const company_ = company || company2;
  const schoolName = company_ && company_.urn;
  const [endDate, setEndDate] = useState("");
  const [isOfferRepeat, setIsOfferRepeat] = useState(false);
  const [ifLevelExist, setIfLevelExist] = useState(false);
  const [userOffer, setUserOffer] = useState(/** @type UserOffer */ null);
  const [userSubscribe, setUserSubscribe] = useState(/** @type UserSubscribe */ null);

  //checks the possibility of buying an offer again
  useEffect(() => {
    checkOfferRepeat(offer, setIsOfferRepeat);
  }, [offer]);

  //calculate the end date of access
  useEffect(() => {
    calculateEndDateOfAccess(user, setEndDate);
  }, [user]);

  useEffect(() => {
    // find purchased offers
    const offerOfUser = user?.offers?.find((uOffer) => uOffer.offerId === offer.id);
    setUserOffer(offerOfUser);

    const subscribeOfUser = offer && userInfo?.subscribes.find((uSubscribe) => uSubscribe.offerId === offer.id);
    setUserSubscribe(subscribeOfUser);

    // check if the level exists
    if (!user) {
      setIfLevelExist(false);
    } else {
      user.levelPacks === undefined
        ? setIfLevelExist(false)
        : setIfLevelExist(offer.levelPacks.filter((level) => user.levelPacks.includes(level)).length > 0);
    }
  }, [user, userInfo, offer]);

  return (
    <Grid item xs={12} sm={6} md={size}>
      <Card className={classes.card}>
        <CardMedia
          component="img"
          className={classes.media}
          alt={offer.image.name}
          height="140"
          image={offer.image.url}
          title={offer.image.name}
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5">
            {offer.name}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            {offer.description}
          </Typography>
          {offer.isSubscribe && offer.subscribe && (
            <>
              <Typography variant="body2">{t(every(offer.subscribe.every))}</Typography>
              {userSubscribe?.endTime && (
                <Typography variant="body2">
                  {t("expired")}: {userSubscribe.endTime}
                </Typography>
              )}
            </>
          )}
          <div className={classes.priceLabel}>
            {offer.oldPrice && offer.oldPrice > offer.price ? (
              <>
                <Typography className={classes.oldPrice} variant="subtitle1">
                  {offer.oldPrice} {offer.currency.toUpperCase()}
                </Typography>
                <Typography className={classes.newPrice} variant="subtitle1">
                  {offer.price} {offer.currency.toUpperCase()}
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle1" className={classes.price}>
                {offer.price} {offer.currency.toUpperCase()}
              </Typography>
            )}
          </div>
        </CardContent>
        <CardActions>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs container direction="row">
              <Grid item>
                <PrimaryButton
                  className={classes.cardBtn}
                  onClick={() =>
                    history.push({
                      pathname: `${companyName ? "/" + companyName : ""}/offer/${schoolName}/${offer.id}`,
                    })
                  }
                >
                  <Typography>{t("details")}</Typography>
                </PrimaryButton>
              </Grid>
              <Grid item>
                <BuyOrSubscribeButton {...{ offer, isOfferRepeat, userOffer, userSubscribe }} />
              </Grid>
              <Grid item>
                <DiscardButton
                  className={classes.cardBtn}
                  offer={offer}
                  user={user}
                  offerProceed={userOffer?.status === "pending"}
                />
              </Grid>
            </Grid>
            {offer.isSubscribe && offer.subscribe && (
              <Grid item xs>
                <Typography variant="body2" className={classes.firstPrice} align="right" gutterBottom>
                  {t("firstPayment")}{" "}
                  <strong>
                    {offer.subscribe.firstPrice} {offer.currency.toUpperCase()}
                  </strong>
                </Typography>
              </Grid>
            )}
            {ifLevelExist && !userOffer && (
              <Grid item xs>
                <Typography variant="body2" className={classes.levelsExistMessage} component="small">
                  {t("levelPackExist")}
                </Typography>
              </Grid>
            )}
            {userOffer?.status === "pending" && (
              <Grid item xs>
                <Typography variant="body2" className={classes.levelsExistMessage} component="small">
                  {t("awaitingPayment")}
                </Typography>
              </Grid>
            )}
            {userOffer?.paid && !isOfferRepeat && (
              <Grid item xs>
                <Typography variant="body2" className={classes.levelsExistMessage} component="small">
                  {t("bought")}
                </Typography>
              </Grid>
            )}
            {userOffer?.paid && isOfferRepeat && (
              <Grid item xs>
                <Typography variant="body2" className={classes.levelsExistMessage} component="small">
                  {t("boughtUntilDate", { endDate })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

OfferCard.defaultProps = {
  size: 6,
};

OfferCard.propTypes = {
  offer: PropTypes.object,
  user: PropTypes.object,
  size: PropTypes.number,
};

export default OfferCard;
